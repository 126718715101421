import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const CreateEveryday = () => (
  <Layout>
    <SEO
      title="How to Create Every Day"
      description="One of the main things that stop people from starting to create content is this fear that they will run out of ideas"
    />
    <h1>How to Create Every Day</h1>

    <h2>Trust That You Will Find Ideas</h2>
    <p>One of the main things that stop people from starting to create content is this fear that they will run out of ideas. I too had the same fear, then I just pushed it aside and got on with it. The thing is that there will be days when you struggle to come up with something new but it always comes eventually.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/NkWhTnh.jpg"
        alt="how to create ever day"
        description="One of the main things that stop people from starting to create content is this fear that they will run out of ideas, here's how you can create ideas everday"
      />
    </div>
    <p>
      Photo by {" "}
      <a
        href="https://unsplash.com/@markfb"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Mark Fletcher-Brown
      </a>
       {" "}on Unsplash
    </p>

    <p>If you keep the goal of new content with you at all times, you will find yourself organising your thoughts throughout the day. You will see inspiration where you might not have seen any before and ideas will come to you without you having to think about it. Most of the time.</p>
    <p>Every day is a new day and yes, you might be in a routine which means taking the same route and being around the same people. But, there are things that change around you all the time and by being focussed on creating new content daily, you will train yourself to see them.</p>
    <p>If you need more inspiration and a mentor of sorts, look to your favourite creatives. People who make music, write books, create tv shows and movies. They always find a way to come up with new things and they are just as human as you are.</p>
    <p>Lastly, trends are a real thing and if you must, steal and borrow from them. Trends are forever changing and in this age of social media, they come and go faster than ever. Take what you must and never worry about coming up with a new idea again.</p>

    <h2>Create realistic goals</h2>
    <p>An important thing to do before you start is work on the actual goal. What do you want to achieve and how much time do you want to spend? In my case, I wanted to build a habit. I knew that if I did something for so long, it would become part of me and I wouldn’t have to find motivation. I believe that habit supersedes motivation.</p>
    <p>So when it comes to setting the type of work and amount, you need to find a balance. It can’t be so little that it’s too easy to do and takes no effort. At the other end of the scale, it can’t be too hard or time consuming that you fail time and time again then end up giving up.</p>
    <p>It might take a bit of experimentation if you haven’t tried this before. It might be worth leaning towards the more conservative end. That way you can achieve your first goal and then you can just keep working. It will feel better to overachieve than to miss the mark.</p>
    <p>There’s always the option of doing a trial run to find the workload that works for you. You could set an amount to stick to for 4 weeks then adjust after that period then carry on for a full year.</p>

    <h2>Work towards an overall goal</h2>
    <p>I touched on this in the previous section. There are generally two goal types; short term and long term. The last section was on, as you’ve figured out, short term goals. The things that you can think more frequently and have on the front of your mind to keep you going.</p>
    <p>I see long term goals as something you put away and only think about once in a while to make sure you are still on track. They can be the driving force in your life but thinking about them constantly might bring you anxiety because they are so far away.</p>
    <p>The goal might be to earn a certain amount of money. To make it into a certain role that requires experience and a portfolio of work. Even to have a visual and list of things that make up your dream lifestyle.</p>

    <h2>Solo or group work</h2>
    <p>Unless you have a partner who is completely aligned with you and has the drive to keep going when things get tough and not get distracted with other opportunities, I would go it alone. There is nothing wrong with working with someone else but when you tie your success to someone else, if they fail then so do you.</p>
    <p>I would say that if you want to work with someone else, make sure you have a back up plan in place that lets you still meet your goals. Just in a different way. There’s also the option of doing your work every day for a set time period, then joining with a partner once you’ve built the habit, drastically increasing your chances of success.</p>

    <h2>Should You Tell anyone or Keep it a Secret</h2>
    <p>This one is all dependent on the type of person you are. Starting with my own personal experience, I know that I cannot tell anyone the full details of my plan. I can say that I am working on something and roughly what it’s about. Beyond that and when I get to particulars, I find that shortly after, I lose motivation.</p>
    <p>Then everything gets harder to do and I might even end up giving up to focus on something else. I’m sure there is some psychology around this and I’m aware it affects a lot of people so I’m not alone.</p>
    <p>That’s why I keep my goals to myself until I’ve made progress. That way I’m backing up my claims with something and I’m far less likely to be negatively affected by their feedback.</p>
    <p>When I told people my plans, there were generally two things that happened. The first is that they liked it and urged me on. This made me feel like I had already accomplished something so the job of actually doing the work became less important. The second was that they didn’t like it and this led me to question the importance of what I was doing.</p>
    <p>This could still be a problem for me today, I’m not sure. The good thing is that I won’t find out because I have mechanisms in place to avoid the situation in the first place. My motivation comes from within first.</p>




    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default CreateEveryday
